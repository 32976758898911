import { Route, Routes, Navigate } from "react-router-dom";
import "./App.scss";
import Login from "./pages/login/Login";
import Profile from "./pages/profile/Profile";
import Register from "./pages/register/Register";

import AdminProfile from "./pages/admin/admin-profile/AdminProfile";
import AdminLogin from "./pages/admin/admin-login/AdminLogin";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        {/* <Route path="/:id" element={<Main />} /> */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/profile" element={<Profile />} />

        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/admin-profile" element={<AdminProfile />} />
      </Routes>
    </>
  );
}

export default App;