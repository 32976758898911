import React, { useState, useEffect, useRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import logo from '../../images/bank.svg'
import icon1 from './images/1.png'
import icon2 from './images/2.png'
import icon3 from './images/3.png'
import icon4 from './images/4.png'

import flag1 from './images/flags/1.png'
import flag2 from './images/flags/2.png'
import flag3 from './images/flags/3.png'
import flag5 from './images/flags/5.png'

import { ArrowPathRoundedSquareIcon, BeakerIcon, CheckCircleIcon, ExclamationCircleIcon, PaperAirplaneIcon, QrCodeIcon, WalletIcon, XMarkIcon } from '@heroicons/react/24/solid'

function Profile() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const accNav = useRef(null)

  useEffect(() => {
    const token = localStorage.getItem('token');

    fetch('https://asp-bank-380c09dd3c6d.herokuapp.com/users/check-token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.valid) {
          setLoading(false);
          setUser(data.user);

          console.log(data.user);
        } else {
          window.location.href = '/login';
        }
      })
      .catch(error => {
        console.error('Error:', error);
      })
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  const getInitials = (firstName, lastName) => {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  };


  const [strings, setStrings] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://asp-bank-380c09dd3c6d.herokuapp.com/data/get-strings');
        const data = await response.json();
        setStrings(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  const [formValues, setFormValues] = useState({ cardNumber: '', purpose: '', amount: '' });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedFormValues = { ...formValues, [name]: value };
    setFormValues(updatedFormValues);

    // Проверка на заполненность всех полей
    const allFieldsFilled = Object.values(updatedFormValues).every(field => field.trim() !== '');
    setIsButtonDisabled(!allFieldsFilled);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Your request for funds transfer has been completed, awaiting confirmation by the financial department!');
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
     {isModalOpen && (
      <div className='sent-modal fixed  bg-black bg-opacity-50 w-full h-[100vh] flex justify-center items-center'>
        <div className='w-[280px] bg-white p-4 rounded-xl'>
          <div className='flex justify-between mb-8 items-center'>
            <h2 className='font-bold text-[18px]  md:text-[22px]'>
              Transfer money
            </h2>
            <XMarkIcon className='w-6 h-6 cursor-pointer' onClick={closeModal}/>

          </div>

       
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700">recipient's card number</label>
              <input
                type="text"
                name="cardNumber"
                className="w-full p-2 border rounded"
                value={formValues.cardNumber}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">purpose of translation</label>
              <input
                type="text"
                name="purpose"
                className="w-full p-2 border rounded"
                value={formValues.purpose}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">transfer amount</label>
              <input
                type="text"
                name="amount"
                className="w-full p-2 border rounded"
                value={formValues.amount}
                onChange={handleChange}
                required
              />
            </div>
            <button
              type="submit"
              className={`px-4 py-2 text-white rounded ${isButtonDisabled ? 'bg-gray-400' : 'bg-blue-600'}`}
              disabled={isButtonDisabled}
            >
              Apply
            </button>
          </form>
        </div>
      </div>
)}
      {loading ? (
        <div className="flex h-[100vh] justify-center items-center">Loading...</div>
      ) : (
        <div className='content bg-[#F2F2F2]'>
          <div className='border-b-2 border-gray-300 p-4 flex justify-between items-center'>
            <div className='flex gap-2 items-center'>
              <h1 className="text-xl flex gap-2 justify-center font-bold leading-tight tracking-tight text-black md:text-2xl ">
                <svg className='w-8 h-8' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill='red' d="M243.4 2.6l-224 96c-14 6-21.8 21-18.7 35.8S16.8 160 32 160v8c0 13.3 10.7 24 24 24H456c13.3 0 24-10.7 24-24v-8c15.2 0 28.3-10.7 31.3-25.6s-4.8-29.9-18.7-35.8l-224-96c-8-3.4-17.2-3.4-25.2 0zM128 224H64V420.3c-.6 .3-1.2 .7-1.8 1.1l-48 32c-11.7 7.8-17 22.4-12.9 35.9S17.9 512 32 512H480c14.1 0 26.5-9.2 30.6-22.7s-1.1-28.1-12.9-35.9l-48-32c-.6-.4-1.2-.7-1.8-1.1V224H384V416H344V224H280V416H232V224H168V416H128V224zM256 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" /></svg>
                ASP BANK
              </h1>

            </div>
            <div className='flex items-center space-x-2'>
              <div className='bg-gray-200 relative cursor-pointer w-12 rounded-full text-gray-500 h-12 flex justify-center items-center'>
                <svg onClick={() => {
                  accNav.current.classList.toggle('hidden')
                }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                  <path fill-rule="evenodd" d="M18.685 19.097A9.723 9.723 0 0 0 21.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 0 0 3.065 7.097A9.716 9.716 0 0 0 12 21.75a9.716 9.716 0 0 0 6.685-2.653Zm-12.54-1.285A7.486 7.486 0 0 1 12 15a7.486 7.486 0 0 1 5.855 2.812A8.224 8.224 0 0 1 12 20.25a8.224 8.224 0 0 1-5.855-2.438ZM15.75 9a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" clip-rule="evenodd" />
                </svg>

                <div ref={accNav} className='absolute hidden w-auto text-black bg-white shadow-2xl rounded-xl p-4 bottom-[-390%]'>
                  <div className='w-12 h-12 bg-gray-500 text-white flex justify-center items-center rounded-full'>
                    {getInitials(user.firstName, user.lastName)}
                  </div>
                  <div>
                    <h1 className='text-l font-bold'>{user.firstName} {user.lastName}</h1>
                    <p className='text-gray-500 text-[12px]'>{user.email}</p>
                  </div>

                  {user.verification ? (
        <div className='border-[#28A745] text-[#28A745] whitespace-nowrap bg-[#E6F4EA] border rounded-xl p-2 flex gap-2 items-center text-[10px] mt-4'>
          <CheckCircleIcon className='w-4 h-4' />
          Verified
        </div>
      ) : (
        <div className='border-[#F29C37] text-[#F29C37] whitespace-nowrap bg-[#FEF6EB] border rounded-xl p-2 flex gap-2 items-center text-[10px] mt-4'>
          <ExclamationCircleIcon className='w-4 h-4' />
          Verification required
        </div>
      )}
                </div>
              </div>
              <div onClick={() => { handleLogout() }} className='bg-red cursor-pointer w-12 rounded-full text-white h-12 flex justify-center items-center'>
                <svg className='w-6 h-6' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75" />
                </svg>
              </div>

            </div>
          </div>
          <div className='flex flex-col w-full overflow-auto'>
            <div className='flex'>
              <div className='w-[320px] p-5 md:block hidden'>
                <ul className='flex-col flex gap-4'>
                  <li className='flex gap-2 items-center hover:text-red hover:bg-gray-100 p-2 rounded-xl cursor-pointer'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                      <path d="M11.47 3.841a.75.75 0 0 1 1.06 0l8.69 8.69a.75.75 0 1 0 1.06-1.061l-8.689-8.69a2.25 2.25 0 0 0-3.182 0l-8.69 8.69a.75.75 0 1 0 1.061 1.06l8.69-8.689Z" />
                      <path d="m12 5.432 8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75V21a.75.75 0 0 1-.75.75H5.625a1.875 1.875 0 0 1-1.875-1.875v-6.198a2.29 2.29 0 0 0 .091-.086L12 5.432Z" />
                    </svg>

                    Main
                  </li>
                  {/* <li className='flex gap-2 items-center hover:text-red hover:bg-gray-100 p-2 rounded-xl cursor-pointer'>
   

                    My account
                  </li> */}
                  <li className='flex gap-2 items-center hover:text-red hover:bg-gray-100 p-2 rounded-xl cursor-pointer'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                      <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 0 1-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 0 1-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 0 1-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584ZM12 18a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" />
                    </svg>

                    Help
                  </li>


                </ul>
              </div>
              <div className='overflow-auto h-[100vh]'>
                <div className='p-5'>
                  <h2 className='font-bold text-[24px] md:text-[32px]'>
                    Personal account info
                  </h2>
                  <p>
                    Hello {user.firstName}, welcome back!
                  </p>
                </div>
                <div>
                  <div>
                    <div className='p-5 gap-4 grid md:grid-cols-3 items-start'>
                      <div className='rounded-xl shadow-xl bg-white p-4'>
                        <p className='font-medium md:text-[16px] text-[13px] text-[#717275]'>
                          YOUR DOLLAR BALANCE
                        </p>
                        <p className='text-[42px] font-bold'>
                          ${user.balance_usd}
                        </p>
                        <p>
                          {strings[0].name}
                        </p>
                        <div className='flex pt-6 md:pt-12 justify-between'>
                          <div>
                            <strong>VALID DATE</strong><br />
                            11/25
                          </div>
                          <div>
                            <strong>CARD HOLDER</strong><br />
                            <p className='text-[12px]'>{user.firstName} {user.lastName}</p>
                          </div>
                        </div>
                      </div>
                      <div className='rounded-xl shadow-xl p-4 card-2 text-white'>
                        <p className='font-medium md:text-[16px] text-[13px] text-gray-300'>
                          YOUR EURO BALANCE
                        </p>
                        <p className='text-[42px] font-bold'>
                          €{user.balance_eur}
                        </p>
                        <p>
                          {strings[1].name}
                        </p>
                        <div className='flex pt-6 md:pt-12 justify-between'>
                          <div>
                            <strong>VALID DATE</strong><br />
                            11/25
                          </div>
                          <div>
                            <strong>CARD HOLDER</strong><br />
                            <p className='text-[12px]'>{user.firstName} {user.lastName}</p>
                          </div>
                        </div>
                      </div>
                      <div className='rounded-xl shadow-xl p-4 card-3'>
                        <p className='font-medium md:text-[16px] text-[13px] text-[#717275]'>
                          YOUR USDT BALANCE (TRC - 20)
                        </p>
                        <p className='text-[42px] font-bold'>
                          ${user.balance_usdt}
                        </p>
                        <p className='md:text-[12px] text-[10px]'>
                          {strings[2].name}
                        </p>
                        <div className='flex pt-6 md:pt-12 justify-between'>
                          <div>
                            <strong>VALID DATE</strong><br />
                            11/25
                          </div>
                          <div>
                            <strong>CARD HOLDER</strong><br />
                            <p className='text-[12px]'>{user.firstName} {user.lastName}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='flex md:flex-row flex-col-reverse gap-2'>
                      <div className='md:w-2/3 m-5 pb-44 md:pb-0'>


                        <h2 className='font-bold text-[24px] md:text-[28px]'>
                          Actions
                        </h2>
                        <div className='flex md:flex-row flex-col gap-2 mb-10 mt-4'>
                          <div className='rounded-xl hover:text-red flex gap-2 cursor-pointer transition-all md:hover:scale-105 shadow-xl bg-white p-4'>
                            <WalletIcon className="size-6" />
                            Top up
                          </div>
                          <div className='rounded-xl hover:text-red  flex gap-2 cursor-pointer transition-all md:hover:scale-105 shadow-xl bg-white p-4'>
                            <QrCodeIcon className="size-6" />
                            Scan & Pay
                          </div>
                          <div onClick={openModal} className='rounded-xl hover:text-red  flex gap-2 cursor-pointer transition-all md:hover:scale-105 shadow-xl bg-white p-4'>
                            <PaperAirplaneIcon className="size-6" />
                            Send
                          </div>
                          <div className='rounded-xl hover:text-red  flex gap-2 cursor-pointer transition-all md:hover:scale-105 shadow-xl bg-white p-4'>
                            <ArrowPathRoundedSquareIcon className="size-6" />
                            Request
                          </div>
                        </div>

                        <h2 className='font-bold text-[24px] md:text-[28px]'>
                          About us
                        </h2>
                        <div className='p-5 mt-5 bg-white flex gap-4 shadow-xl rounded-xl'>
                          <Swiper
                            spaceBetween={50}
                            slidesPerView={1}
                            pagination={{ clickable: true }}
                            modules={[Pagination, Autoplay]}
                            autoplay={{
                              delay: 3500,
                              disableOnInteraction: false,
                            }}
                          >
                            <SwiperSlide>
                              <div className='flex flex-col gap-2 pb-14'>
                                <img className='w-8 h-8' src={icon1} alt='Icon 1' />
                                <h2 className='font-bold text-[20px]'>
                                  The community Bank in Malta
                                </h2>
                                <p>
                                  For over 100 years, our purpose has been to serve the community, leaving no one behind.
                                </p>
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className='flex flex-col gap-2'>
                                <img className='w-8 h-8' src={icon2} alt='Icon 2' />
                                <h2 className='font-bold text-[20px]'>
                                  The community Bank in Malta
                                </h2>
                                <p>
                                  For over 100 years, our purpose has been to serve the community, leaving no one behind.
                                </p>
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className='flex flex-col gap-2'>
                                <img className='w-8 h-8' src={icon3} alt='Icon 3' />
                                <h2 className='font-bold text-[20px]'>
                                  The community Bank in Malta
                                </h2>
                                <p>
                                  For over 100 years, our purpose has been to serve the community, leaving no one behind.
                                </p>
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className='flex flex-col gap-2'>
                                <img className='w-8 h-8' src={icon4} alt='Icon 4' />
                                <h2 className='font-bold text-[20px]'>
                                  The community Bank in Malta
                                </h2>
                                <p>
                                  For over 100 years, our purpose has been to serve the community, leaving no one behind.
                                </p>
                              </div>
                            </SwiperSlide>
                          </Swiper>
                        </div>
                      </div>
                      <div className='md:w-1/3 md:m-5'>
                        <div className='p-5'>
                          <h2 className='font-bold text-[24px] md:text-[28px]'>
                            Exchange Rate
                          </h2>
                          <div>
                            <div className='flex justify-between border-b pb-2 pt-2'>
                              <div className='flex gap-2 items-center'>
                                <img className='w-16 rounded-lg' src={flag1} />
                                <div>
                                  <h3>
                                    USD
                                  </h3>
                                  <p className='text-[12px]'>
                                    1 US Dollar
                                  </p>
                                </div>
                              </div>

                              <div className='flex justify-between gap-2'>
                                <div>
                                  <p className='text-[14px] md:text-[16px]'>
                                    SELL
                                    <br />
                                    1.0931
                                  </p>
                                </div>
                                <div>
                                  <p className='text-[14px] md:text-[16px]'>
                                    BUY

                                    <br />
                                    1.0821
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className='flex justify-between border-b pb-2 pt-2'>
                              <div className='flex gap-2 items-center'>
                                <img className='w-16 rounded-lg border-2' src={flag2} />
                                <div>
                                  <h3>
                                    SGD
                                  </h3>
                                  <p className='text-[12px]'>
                                    1 Singapore Dollar
                                  </p>
                                </div>
                              </div>
                              <div className='flex justify-between gap-2'>
                                <div>
                                  <p className='text-[14px] md:text-[16px]'>
                                    SELL
                                    <br />
                                    0.6901
                                  </p>
                                </div>
                                <div>
                                  <p className='text-[14px] md:text-[16px]'>
                                    BUY
                                    <br />
                                    0.6201
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className='flex justify-between border-b pb-2 pt-2'>
                              <div className='flex gap-2 items-center'>
                                <img className='w-16 rounded-lg' src={flag3} />
                                <div>
                                  <h3>
                                    GPD
                                  </h3>
                                  <p className='text-[12px]'>
                                    1 British Pound
                                  </p>
                                </div>
                              </div>
                              <div className='flex justify-between gap-2'>
                                <div>
                                  <p className='text-[14px] md:text-[16px]'>
                                    SELL
                                    <br />
                                    1.1520
                                  </p>
                                </div>
                                <div>
                                  <p className='text-[14px] md:text-[16px]'>
                                    BUY

                                    <br />
                                    1.1412
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className='flex justify-between border-b pb-2 pt-2'>
                              <div className='flex gap-2 items-center'>
                                <img className='w-16 rounded-lg border-2' src={flag5} />
                                <div>
                                  <h3>
                                    EUR
                                  </h3>
                                  <p className='text-[12px]'>
                                    1 Euro
                                  </p>
                                </div>
                              </div>
                              <div className='flex justify-between gap-2'>
                                <div>
                                  <p className='text-[14px] md:text-[16px]'>
                                    SELL
                                    <br />
                                    1.1020
                                  </p>
                                </div>
                                <div>
                                  <p className='text-[14px] md:text-[16px]'>
                                    BUY
                                    <br />
                                    1.1010
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='pb-44 text-center'>
                      Copyright © ASP BANK 2024
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>

  );
}

export default Profile;