import { useRef, useState } from 'react';

function Register() {
    const email = useRef(null);
    const password = useRef(null);
    const address = useRef(null);
    const firstName = useRef(null);
    const lastName = useRef(null);
    const phone = useRef(null);
    const dob = useRef(null);
    const [getError, setError] = useState();

    const handleSubmit = async (e) => {
        e.preventDefault();
        fetch('https://asp-bank-380c09dd3c6d.herokuapp.com/users/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email.current.value,
                password: password.current.value,
                address: address.current.value,
                first_name: firstName.current.value,
                last_name: lastName.current.value,
                phone: phone.current.value,
                date_of_birth: dob.current.value,
            }),
        })
        .then(response => response.json())
        .then(data => {
            if (data.message == 'User registered successfully') {
                window.location.href = '/login';
            } else {
                setError(data.message || 'Registration failed');
            }
        })
        .catch(error => {
            console.error('Error:', error);
            setError(error.toString());
        });
    };

    return (
        <section className="bg-gray-50">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl flex gap-2 justify-center font-bold leading-tight tracking-tight text-black md:text-2xl ">
                            <svg className='w-8 h-8' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M243.4 2.6l-224 96c-14 6-21.8 21-18.7 35.8S16.8 160 32 160v8c0 13.3 10.7 24 24 24H456c13.3 0 24-10.7 24-24v-8c15.2 0 28.3-10.7 31.3-25.6s-4.8-29.9-18.7-35.8l-224-96c-8-3.4-17.2-3.4-25.2 0zM128 224H64V420.3c-.6 .3-1.2 .7-1.8 1.1l-48 32c-11.7 7.8-17 22.4-12.9 35.9S17.9 512 32 512H480c14.1 0 26.5-9.2 30.6-22.7s-1.1-28.1-12.9-35.9l-48-32c-.6-.4-1.2-.7-1.8-1.1V224H384V416H344V224H280V416H232V224H168V416H128V224zM256 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>
                             ASP BANK
                        </h1>
                        <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                            <div>
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-black">Email</label>
                                <input ref={email} type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="your.email@example.com" required />
                            </div>
                            <div>
                                <label htmlFor="password" className="block mb-2 text-sm font-medium text-black">Password</label>
                                <input ref={password} type="password" name="password" id="password" className="bg-gray-50 border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="••••••••" required />
                            </div>
                            <div>
                                <label htmlFor="address" className="block mb-2 text-sm font-medium text-black">Address</label>
                                <input ref={address} type="text" name="address" id="address" className="bg-gray-50 border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Your address" required />
                            </div>
                            <div className='flex gap-2'>
                                <div>
                                    <label htmlFor="firstName" className="block mb-2 text-sm font-medium text-black">First Name</label>
                                    <input ref={firstName} type="text" name="firstName" id="firstName" className="bg-gray-50 border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="First name" required />
                                </div>
                                <div>
                                    <label htmlFor="lastName" className="block mb-2 text-sm font-medium text-black">Last Name</label>
                                    <input ref={lastName} type="text" name="lastName" id="lastName" className="bg-gray-50 border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Last name" required />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="phone" className="block mb-2 text-sm font-medium text-black">Phone</label>
                                <input ref={phone} type="tel" name="phone" id="phone" className="bg-gray-50 border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Your phone number" required />
                            </div>
                            <div>
                                <label htmlFor="dob" className="block mb-2 text-sm font-medium text-black">Date of Birth</label>
                                <input ref={dob} type="date" name="dob" id="dob" className="bg-gray-50 border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" required />
                            </div>
                            {getError && <div className="text-red-500">{getError}</div>}
                            <button type="submit" className="w-full text-white bg-red focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Sign up</button>
                            <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                Already have an account? <a href="/login" className="font-medium text-red hover:underline">Log in</a>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Register;