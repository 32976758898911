import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import EditUser from '../../../components/edit-user/EditUser';
import { format } from 'date-fns';
import StringsEditor from '../../../components/strings-editor/StringsEditor';

function AdminProfile() {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');

    fetch('https://asp-bank-380c09dd3c6d.herokuapp.com/admins/check-token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.valid) {
          fetch('https://asp-bank-380c09dd3c6d.herokuapp.com/admins/get-users', {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
            .then(response => response.json())
            .then(usersData => {
              setUsers(usersData);
              setLoading(false);
            });
        } else {
          window.location.href = '/login';
        }
      })
      .catch(error => {
        console.error('Error:', error);
        setLoading(false);
      });
  }, []);

  const handleUserSelect = (user) => {
    setSelectedUser(user);
  };

  const handleSave = () => {
    // Reload users after saving
    const token = localStorage.getItem('token');
    fetch('https://asp-bank-380c09dd3c6d.herokuapp.com/admins/get-users', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => response.json())
      .then(usersData => {
        setUsers(usersData);
        setSelectedUser(null); // Deselect user after saving
      });
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  };


  return (
    <>
      {loading ? (
        <div className="flex h-[100vh] justify-center items-center">Loading...</div>
      ) : (
        <div className='content bg-gray-50'>
          <div className='flex flex-col w-full overflow-auto'>
            <div className='flex'>
              <div className='w-[320px] bg-white shadow p-5'>
                <ul className='flex-col flex gap-4'>
                  <li>
                      <h1 className="text-xl flex gap-2 mb-6 font-bold leading-tight tracking-tight text-black md:text-2xl ">
                    <svg className='w-8 h-8' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill='red' d="M243.4 2.6l-224 96c-14 6-21.8 21-18.7 35.8S16.8 160 32 160v8c0 13.3 10.7 24 24 24H456c13.3 0 24-10.7 24-24v-8c15.2 0 28.3-10.7 31.3-25.6s-4.8-29.9-18.7-35.8l-224-96c-8-3.4-17.2-3.4-25.2 0zM128 224H64V420.3c-.6 .3-1.2 .7-1.8 1.1l-48 32c-11.7 7.8-17 22.4-12.9 35.9S17.9 512 32 512H480c14.1 0 26.5-9.2 30.6-22.7s-1.1-28.1-12.9-35.9l-48-32c-.6-.4-1.2-.7-1.8-1.1V224H384V416H344V224H280V416H232V224H168V416H128V224zM256 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" /></svg>
                    ASP BANK
                  </h1>
                  </li>
                  <li onClick={() => {
                    setSelectedUser(null);
                  }} className='flex gap-2 items-center hover:text-red  rounded-xl cursor-pointer'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                      <path fill-rule="evenodd" d="M8.25 6.75a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM15.75 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM2.25 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM6.31 15.117A6.745 6.745 0 0 1 12 12a6.745 6.745 0 0 1 6.709 7.498.75.75 0 0 1-.372.568A12.696 12.696 0 0 1 12 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 0 1-.372-.568 6.787 6.787 0 0 1 1.019-4.38Z" clip-rule="evenodd" />
                      <path d="M5.082 14.254a8.287 8.287 0 0 0-1.308 5.135 9.687 9.687 0 0 1-1.764-.44l-.115-.04a.563.563 0 0 1-.373-.487l-.01-.121a3.75 3.75 0 0 1 3.57-4.047ZM20.226 19.389a8.287 8.287 0 0 0-1.308-5.135 3.75 3.75 0 0 1 3.57 4.047l-.01.121a.563.563 0 0 1-.373.486l-.115.04c-.567.2-1.156.349-1.764.441Z" />
                    </svg>
                    Users
                  </li>

                  <li className='flex gap-2 items-center hover:text-red  rounded-xl cursor-pointer'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                      <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5H5.625Z" />
                      <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                    </svg>


                    Docs
                  </li>
                  <li className='flex gap-2'>
                  <a href='/profile' className="px-4 py-2 bg-blue-600 text-white rounded">To profile</a>
                  <button onClick={handleLogout} className="px-4 py-2 bg-red text-white rounded">Logout</button>

                  </li>
                  <li className='flex pt-8 border-t-2 gap-2 items-center hover:text-red  rounded-xl cursor-pointer'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                      <path d="M4.5 3.75a3 3 0 0 0-3 3v.75h21v-.75a3 3 0 0 0-3-3h-15Z" />
                      <path fill-rule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3v-7.5Zm-18 3.75a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5h-6a.75.75 0 0 1-.75-.75Zm.75 2.25a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Z" clip-rule="evenodd" />
                    </svg>
                    Cards:
                  </li>
                  <li>
                    <StringsEditor />
                  </li>
                  <li className='flex text-[12px] pt-8 border-t-2 gap-2 items-center hover:text-red  rounded-xl cursor-pointer'>
                    Info: <br/>
                    Verification 1 = верифицирован <br/>
                    Verification 0 = не верифицирован
                  </li>
                </ul>
              </div>
              <div className='overflow-auto h-[100vh] flex-1'>
                <div className="p-5">
                  {selectedUser ? (
                    <EditUser user={selectedUser} onSave={handleSave} />
                  ) : (
                    <>
                      <h2 className="text-xl font-bold mb-4">Users</h2>
                      <table className="min-w-full bg-white">
                        <thead>
                          <tr>
                            <th className="py-2 px-4 border-b">ID</th>
                            <th className="py-2 px-4 border-b">Email</th>
                            <th className="py-2 px-4 border-b">Phone</th>
                            <th className="py-2 px-4 border-b">Address</th>
                            <th className="py-2 px-4 border-b">Name</th>
                            <th className="py-2 px-4 border-b">Created At</th>
                            <th className="py-2 px-4 border-b">Balance USD</th>
                            <th className="py-2 px-4 border-b">Balance EUR</th>
                            <th className="py-2 px-4 border-b">Balance USDT</th>
                            <th className="py-2 px-4 border-b">Verification</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users
                          .filter(user => user.role !== 'admin')
                          .map(user => (
                            <tr key={user.id} className="hover:bg-gray-100 cursor-pointer" onClick={() => handleUserSelect(user)}>
                              <td className="py-2 px-4 border-b">{user.id}</td>
                              <td className="py-2 px-4 border-b">{user.email}</td>
                              <td className="py-2 px-4 border-b">{user.phone}</td>
                              <td className="py-2 px-4 border-b">{user.address}</td>
                              <td className="py-2 px-4 border-b">{user.first_name} {user.last_name}</td>
                              <td className="py-2 px-4 border-b">{format(new Date(user.created_at), 'dd/MM/yyyy')}</td>
                              <td className="py-2 px-4 border-b">{user.balance_usd}</td>
                              <td className="py-2 px-4 border-b">{user.balance_eur}</td>
                              <td className="py-2 px-4 border-b">{user.balance_usdt}</td>
                              <td className="py-2 px-4 border-b">{user.verification}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AdminProfile;