import { useRef, useState } from 'react';

function AdminLogin() {
    let email = useRef(null);
    let pass = useRef(null);
    let [getError, setError] = useState();

    const handleSubmit = async (e) => {
        e.preventDefault();
            fetch('https://asp-bank-380c09dd3c6d.herokuapp.com/admins/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email.current.value,
                    password: pass.current.value,
                }),
            })
            .then(response => response.json())
            .then(data => {
                if (data.token) {
                    localStorage.setItem('token', data.token);
                    window.location.href = '/admin-profile';
                } else {
                    console.error('Invalid username or password');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                setError(error.toString())
            });
    };


    return (
        <section className="bg-gray-50">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                            <div>
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-black ">Your name</label>
                                <input ref={email} type="text" name="email" id="name" className="bg-gray-50 border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:placeholder-gray-400  " placeholder="login123" required />
                            </div>
                            <div>
                                <label htmlFor="password" className="block mb-2 text-sm font-medium text-black ">Password</label>
                                <input ref={pass} type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:placeholder-gray-400  " required />
                            </div>
                            {getError && <div className="text-red-500">{getError}</div>}
                            <button type="submit" className="w-full text-white bg-red focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Sign in</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default AdminLogin;