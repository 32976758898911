import React, { useState, useEffect } from 'react';

function StringsEditor() {
  const [strings, setStrings] = useState([]); // Состояние для хранения строк
  const [selectedString, setSelectedString] = useState(null); // Состояние для хранения выбранной строки
  const [editedString, setEditedString] = useState(''); // Состояние для хранения отредактированной строки
  const token = localStorage.getItem('token');

  useEffect(() => {
    // Загрузка строк с сервера при монтировании компонента
    fetch('https://asp-bank-380c09dd3c6d.herokuapp.com/data/get-strings')
      .then(response => response.json())
      .then(data => {
        setStrings(data)
        console.log(data);
      })
      .catch(error => console.error('Error fetching strings:', error));
  }, []); // Загрузка строк происходит только один раз при монтировании компонента

  const handleSelectString = (string) => {
    setSelectedString(string); // Установка выбранной строки для редактирования
    setEditedString(string.name); // Установка отредактированной строки в начальное значение выбранной строки
  };

  const handleSaveString = () => {
    // Отправка отредактированной строки на сервер
    fetch(`https://asp-bank-380c09dd3c6d.herokuapp.com/admins/update-string/${selectedString.id}`, { // Добавляем id строки в URL запроса
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ newName: editedString }), // Отправляем новое имя строки
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          // Если сохранение успешно, обновляем состояние строк
          setStrings(strings.map(str => (str.id === selectedString.id ? { ...str, name: editedString } : str))); // Обновляем имя строки в состоянии
          setSelectedString(null); // Сбрасываем выбранную строку после сохранения
        } else {
          console.error('Error saving string:', data.error);
        }
      })
      .catch(error => console.error('Error saving string:', error));
  };

  return (
    <div>
      {/* <h2>String Editor</h2> */}
      <ul>
        {/* {strings.map(string => (
          <li key={string.id} className='text-black'>{string.name}</li>
        ))} */}
        {strings.map(string => (
          <li key={string.id} className='text-black flex flex-col' onClick={() => handleSelectString(string)}>
            {selectedString && selectedString.id === string.id ? (
              <input className='p-2 border' type="text" value={editedString} onChange={e => setEditedString(e.target.value)} />
            ) : (
              <span className='p-2 border'>{string.name}</span>
            )}
          </li>
        ))}
      </ul>
      {selectedString && (
        <button className="px-4 py-2 mt-4 bg-blue-600 text-white rounded" onClick={handleSaveString}>Save</button>
      )}
    </div>
  );
}

export default StringsEditor;