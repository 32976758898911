import React, { useState } from 'react';

function EditUser({ user, onSave }) {
    const [formData, setFormData] = useState({
        email: user.email,
        address: user.address,
        first_name: user.first_name,
        last_name: user.last_name,
        phone: user.phone,
        // date_of_birth: user.date_of_birth,
        balance_usd: user.balance_usd,
        balance_eur: user.balance_eur,
        balance_usdt: user.balance_usdt,
        verification: user.verification,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch(`https://asp-bank-380c09dd3c6d.herokuapp.com/admins/update-user/${user.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(formData),
        })
            .then(response => response.json())
            .then(data => {
                if (data.message === 'User updated successfully') {
                    onSave();
                } else {
                    console.error('Update failed');
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const handleDeleteUser = (userId) => {
        const token = localStorage.getItem('token');
        fetch(`https://asp-bank-380c09dd3c6d.herokuapp.com/admins/delete-user/${userId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response => response.json())
        .then(data => {
          if (data.message === 'User deleted successfully') {
            onSave();
          } else {
            console.error(data.error);
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
      };
    

    return (
        <form onSubmit={handleSubmit} className="p-4">
            <div className="mb-4">
                <h3 className='font-bold text-2xl'>
                    Edit {formData.first_name} {formData.last_name}
                </h3>
            </div>
            <div className="mb-4">
                <label className="block text-gray-700">Email</label>
                <input type="email" name="email" value={formData.email} onChange={handleChange} className="w-full p-2 border rounded" required />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700">Address</label>
                <input type="text" name="address" value={formData.address} onChange={handleChange} className="w-full p-2 border rounded" required />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700">First Name</label>
                <input type="text" name="first_name" value={formData.first_name} onChange={handleChange} className="w-full p-2 border rounded" required />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700">Last Name</label>
                <input type="text" name="last_name" value={formData.last_name} onChange={handleChange} className="w-full p-2 border rounded" required />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700">Phone</label>
                <input type="text" name="phone" value={formData.phone} onChange={handleChange} className="w-full p-2 border rounded" required />
            </div>
            {/* <div className="mb-4">
        <label className="block text-gray-700">Date of Birth</label>
        <input type="date" name="date_of_birth" value={formData.date_of_birth} onChange={handleChange} className="w-full p-2 border rounded" required />
      </div> */}
            <div className="mb-4">
                <label className="block text-gray-700">Balance USD</label>
                <input type="text" name="balance_usd" value={formData.balance_usd} onChange={handleChange} className="w-full p-2 border rounded" required />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700">Balance EUR</label>
                <input type="text" name="balance_eur" value={formData.balance_eur} onChange={handleChange} className="w-full p-2 border rounded" required />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700">Balance USDT</label>
                <input type="text" name="balance_usdt" value={formData.balance_usdt} onChange={handleChange} className="w-full p-2 border rounded" required />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700">Verification</label>
                <input type="text" name="verification" value={formData.verification} onChange={handleChange} className="w-full p-2 border rounded" required />
            </div>
            <div className='flex gap-2'>
            <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded">Save</button>
            <div className='bg-red flex gap-2 px-4 py-2 text-white rounded' onClick={() => handleDeleteUser(user.id)}>
                Delete
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                    <path fill-rule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z" clip-rule="evenodd" />
                </svg>
            </div>
            </div>

        </form>
    );
}

export default EditUser;